import { createMutation, createQuery } from "react-query-kit";
import { getTask, verifySocialTask } from "./request";
import {
  ISocialTaskResponse,
  IVerifySocialTaskRequest,
  IVerifySocialTaskResponse,
} from "./types";

export const useVerifySocialTask = createMutation<
  IVerifySocialTaskResponse,
  IVerifySocialTaskRequest
>({
  mutationFn: (variables: IVerifySocialTaskRequest) =>
    verifySocialTask(variables),
});

export const useSocialTask = createQuery<
  ISocialTaskResponse[],
  { social: string }
>({
  queryKey: ["useSocialTask"],
  fetcher: ({ social }): Promise<ISocialTaskResponse[]> => getTask(social),
});

const REACT_APP_PUBLIC_API_BASE_URL: string = "https://api.kingofshiba.xyz";
const URL_MUSICS: string[] = [
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmRuoe53VsFzTNL9hpE7u1BrK1dS1BEUgJnmCpgYpoEopY",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmQT8raFjHmRiVcnLsEfXWgvVtx13YF3R1QwW3VLJXkDwC",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmXgTMDD2XN3ZxXgpsQh1W1VF5gM9a8X6L628LGA5Vo3MK",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmWAiXwWaa9XSUdYrTN6NBEjbF56BxtiiZsdNZ9vMx5UEa",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmUcHq2BgHCpMWCv4Sd6qR9uaroaWzYPAswsAC5cHCe5Pr",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmTbSkqt6f1KAKNH758oogUzCvDZiqrcYkaYwiyq83oenu",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmTbSkqt6f1KAKNH758oogUzCvDZiqrcYkaYwiyq83oenu",
  "https://brown-slim-sparrow-523.mypinata.cloud/ipfs/QmP3YDZgePL1rzkGJgWhxiQ9VKdREhe8vwEFdxtwKxmgEn",
];
const DEV_MODE: boolean = false;
const EXPLORER_SCAN = "https://testnet.tonviewer.com";
const AIRDROP_WALLET_ADDRESS =
  "0QCpKZXadKtlXdE9YMicPAbm6p8ZF7X5thO4gXGZ6t_C8z3D";
const ENERGY_COLLECTION_WALLET_ADDRESS =
  "0QCpKZXadKtlXdE9YMicPAbm6p8ZF7X5thO4gXGZ6t_C8z3D";
const config = {
  REACT_APP_PUBLIC_API_BASE_URL,
  DEV_MODE,
  URL_MUSICS,
  EXPLORER_SCAN,
  AIRDROP_WALLET_ADDRESS,
  ENERGY_COLLECTION_WALLET_ADDRESS,
};

export default config;

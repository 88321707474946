import GameCoinLogoPng from "@/assets/images/coin-icon.png";
import FacebookLogoPng from "@/assets/images/earn-screen/social-screen/facebook-logo.png";
import InstagramLogoPng from "@/assets/images/earn-screen/social-screen/instagram-logo.png";
import TelegramLogoPng from "@/assets/images/earn-screen/social-screen/telegram-logo.png";
import TikTokLogoPng from "@/assets/images/earn-screen/social-screen/tiktok-logo.png";
import TwitterLogoPng from "@/assets/images/earn-screen/social-screen/twitter-logo.png";
import YoutubeLogoPng from "@/assets/images/earn-screen/social-screen/youtube-logo.png";
import { SocialName } from "@/commons/url-mapping.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import {
  ISocialTaskResponse,
  useSocialTask,
} from "@/services/apis/social-task";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import translations
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TaskDetailModal from "./components/TaskDetailModal";

// Unified social verification function using switch case
const useSocialVerification = (social: string) => {
  let verificationData = null;
  let authLink = null;

  switch (social) {
    case SocialName.YouTube: {
      const googleService = require("@/services/apis/user/google-service");
      const { data: googleVerificationData } =
        googleService.useCheckGoogleVerification();
      const { data: googleAuthLink } = googleService.useGoogleAuthLink();
      verificationData = googleVerificationData;
      authLink = googleAuthLink;
      break;
    }
    case SocialName.Facebook: {
      const facebookService = require("@/services/apis/user/facebook-service");
      const { data: facebookVerificationData } =
        facebookService.useCheckFacebookVerification();
      const { data: facebookAuthLink } = facebookService.useFacebookAuthLink();
      verificationData = facebookVerificationData;
      authLink = facebookAuthLink;
      break;
    }
    case SocialName.Twitter: {
      const twitterService = require("@/services/apis/user/twitter-service");
      const { data: twitterVerificationData } =
        twitterService.useCheckTwitterVerification();
      const { data: twitterAuthLink } = twitterService.useTwitterAuthLink();
      verificationData = twitterVerificationData;
      authLink = twitterAuthLink;
      break;
    }
    case SocialName.Telegram: {
      const telegramService = require("@/services/apis/user/telegram-service");
      const { data: telegramVerificationData } =
        telegramService.useCheckTelegramVerification();
      const { data: telegramAuthLink } = telegramService.useTelegramAuthLink();
      verificationData = telegramVerificationData;
      authLink = telegramAuthLink;
      break;
    }
    case SocialName.TikTok: {
      const tiktokService = require("@/services/apis/user/tiktok-service");
      const { data: tiktokVerificationData } =
        tiktokService.useCheckTikTokVerification();
      const { data: tiktokAuthLink } = tiktokService.useTikTokAuthLink();
      verificationData = tiktokVerificationData;
      authLink = tiktokAuthLink;
      break;
    }
    case SocialName.Instagram: {
      const instagramService = require("@/services/apis/user/instagram-service");
      const { data: instagramVerificationData } =
        instagramService.useCheckInstagramVerification();
      const { data: instagramAuthLink } =
        instagramService.useInstagramAuthLink();
      verificationData = instagramVerificationData;
      authLink = instagramAuthLink;
      break;
    }
    default:
      break;
  }

  return { verificationData, authLink };
};

const SocialTaskScreen: React.FC = () => {
  const { translations } = useConfigStore(); // Access translations
  const { getUserName } = useAuthStore();
  const { social } = useParams<{ social: string }>();

  const { verificationData, authLink } = useSocialVerification(social || "");
  const [isVerified, setIsVerified] = useState<boolean | null>(null);
  const [selectedTask, setSelectedTask] = useState<ISocialTaskResponse | null>(
    null
  );
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  const { data: socialTaskData } = useSocialTask({
    variables: { social: social || "" },
  });

  useEffect(() => {
    if (verificationData) {
      setIsVerified(verificationData.status === "success");
    } else {
      setIsVerified(false);
    }
  }, [verificationData]);

  const handleVerificationClick = () => {
    if (authLink?.auth_link) {
      window.location.href = authLink.auth_link;
    }
  };

  const getSocialImage = () => {
    switch (social) {
      case SocialName.Facebook:
        return FacebookLogoPng;
      case SocialName.YouTube:
        return YoutubeLogoPng;
      case SocialName.Telegram:
        return TelegramLogoPng;
      case SocialName.Twitter:
        return TwitterLogoPng;
      case SocialName.TikTok:
        return TikTokLogoPng;
      case SocialName.Instagram:
        return InstagramLogoPng;
      default:
        return null;
    }
  };

  const infoSocial = {
    image: getSocialImage(),
    description: translations.social_task_screen.finish_tasks_to_earn,
  };

  const renderVerificationMessage = () => {
    if (isVerified === false) {
      return (
        <VerificationMessage
          platform={social || ""}
          onClick={handleVerificationClick}
        />
      );
    }
    return null;
  };

  return (
    <PhoneScreenLayout>
      <TopUserProfile username={getUserName()} />
      <div className="flex flex-col items-center mb-6">
        <img
          src={infoSocial.image}
          alt="Social logo"
          className="w-24 h-24 mb-4"
        />
        <h2 className="text-white text-xl font-semibold">
          {infoSocial.description}
        </h2>
      </div>

      {renderVerificationMessage()}

      <div className="flex-col">
        {isVerified &&
          socialTaskData &&
          socialTaskData.map((task) => (
            <div
              key={task.id}
              className="bg-gradient-card rounded-[16px] flex items-center justify-between p-2 mb-4 shadow-lg transition-transform transform hover:scale-[1.02]"
              onClick={() => {
                setSelectedTask(task);
                setTaskModalOpen(true);
              }}
            >
              <div className="flex items-center">
                <img
                  src={getSocialImage()}
                  alt="Task Logo"
                  className="w-12 h-12 mr-4"
                />
                <h5 className="text-white text-lg font-medium">{task.name}</h5>
              </div>
              <div className="flex items-center">
                <img
                  src={GameCoinLogoPng}
                  alt="Coin Icon"
                  className="w-8 h-8"
                />
                <h5 className="text-white text-lg font-medium mr-2">
                  +{Number(task.reward_amount)}
                </h5>

                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M12 18L18 12L12 6" stroke="white" strokeWidth="2" />
                  <path d="M6 18L12 12L6 6" stroke="white" strokeWidth="2" />
                </svg>
              </div>
            </div>
          ))}
      </div>

      <NavBar />

      <TaskDetailModal
        task={selectedTask}
        open={taskModalOpen}
        onClose={() => setTaskModalOpen(false)}
      />
    </PhoneScreenLayout>
  );
};

export default SocialTaskScreen;

// Reusable Verification Message Component
const VerificationMessage: React.FC<{
  platform: string;
  onClick: () => void;
}> = ({ platform, onClick }) => {
  const { translations } = useConfigStore(); // Access translations

  return (
    <div className="flex flex-col items-center mb-6">
      <p className="text-white text-lg mb-2 text-center">
        {translations.social_task_screen.verify_account.replace(
          "{platform}",
          platform
        )}
      </p>
      <button
        onClick={onClick}
        className="bg-gradient-to-r from-blue-400 to-purple-400 text-white font-bold py-2 px-4 rounded"
      >
        {translations.social_task_screen.verify_now}
      </button>
    </div>
  );
};

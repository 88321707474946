import AirdropIconPng from "@/assets/icons/nav-bar/airdrop.png";
import CenterIconPng from "@/assets/icons/nav-bar/center.png";
import EarnIconPng from "@/assets/icons/nav-bar/earn.png";
import ExploreIconPng from "@/assets/icons/nav-bar/explore.png";
import FriendsIconPng from "@/assets/icons/nav-bar/friends.png";
import { UrlMapping } from "@/commons/url-mapping.common";
import { useConfigStore } from "@/services/stores/useConfigStore";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NavBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const translations = useConfigStore((state) => state.translations);
  const navItems = [
    {
      label: translations.navbar.explore, // Use translated label for Explore
      icon: ExploreIconPng,
      path: UrlMapping.explorer,
      className: "h-8",
    },
    {
      label: translations.navbar.friends, // Use translated label for Friends
      icon: FriendsIconPng,
      path: UrlMapping.friend,
      className: "h-8",
    },
    {
      label: "", // No label for the Center icon
      icon: CenterIconPng,
      path: UrlMapping.home,
      className: "h-14",
    },
    {
      label: translations.navbar.earn, // Use translated label for Earn
      icon: EarnIconPng,
      path: UrlMapping.earn,
      className: "h-8",
    },
    {
      label: translations.navbar.airdrop, // Use translated label for Airdrop
      icon: AirdropIconPng,
      path: UrlMapping.airdrop,
      className: "h-8",
    },
  ];

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <div className="fixed w-full left-0 bottom-0 flex justify-center">
      <div className="py-2 px-2 z-50 w-full max-w-phone-screen">
        <div className="bg-[#4B2E70] flex justify-around items-center rounded-2xl shadow-lg w-full">
          {navItems.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col items-center cursor-pointer transition duration-300 ${
                location.pathname === item.path
                  ? "text-yellow-400"
                  : "text-white"
              }`}
              onClick={() => handleNavigation(item.path)}
            >
              <img
                src={item.icon}
                alt={item.label || "Center"}
                className={`${item.className} ${
                  location.pathname === item.path ? "opacity-100" : "opacity-70"
                }`}
              />
              {item.label && (
                <span className="text-sm mt-0.5">{item.label}</span>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NavBar;

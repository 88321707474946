import BackgroundStarPng from "@/assets/images/card/background-star.png";
import GameCoinLogoPng from "@/assets/images/coin-icon.png";
import DogFacePng from "@/assets/images/dog-face.png";
import RocketIconPng from "@/assets/images/home-screen/rocket-level.png";
import showToast from "@/commons/toast.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import LogoAndBalance from "@/components/logo-and-balance/LogoAndBalance";
import NavBar from "@/components/menus/NavBar";
import PopupModal from "@/components/PopupModal";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import {
  IUpgradeWeight,
  useKingOfShibaUserInfo,
  useUpgradeWeight,
  useUpgradeWeightList,
} from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import for translations
import React, { useEffect, useState } from "react";

type PowerUpModalProps = {
  updateWeight: IUpgradeWeight | null;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const PowerUpModal: React.FC<PowerUpModalProps> = ({
  updateWeight,
  open,
  onConfirm,
  onClose,
}) => {
  const { translations } = useConfigStore(); // Access translations
  return (
    <PopupModal open={open} onClose={onClose}>
      <p className="text-yellow-400 text-lg font-bold">
        {translations.explorer_screen.level} LV.{updateWeight?.require_level}
      </p>
      <p className="text-yellow-400">
        {translations.explorer_screen.sell}:{" "}
        <span className="text-yellow-500">💰{updateWeight?.price}</span>
      </p>
      <div className="w-full relative">
        <img src={BackgroundStarPng} alt="Power-Up" className="w-full" />
        <div className="mb-4 absolute bottom-0 w-full h-full flex justify-center items-center">
          <img src={updateWeight?.image} alt="Power-Up" className="w-3/5" />
        </div>
      </div>
      <div className="text-center mt-6 mb-4">
        <p className="text-white font-bold">
          {translations.explorer_screen.congratulations}
        </p>
        <p className="text-yellow-400">
          <b>
            {translations.explorer_screen.power_up}:{" "}
            {updateWeight?.reward_weight} weight
          </b>
        </p>
      </div>
      <button
        onClick={() => {
          onConfirm();
          onClose();
        }}
        className="bg-gradient-to-r from-blue-400 to-purple-400 text-white font-bold py-3 px-6 rounded-[16px] mt-4 w-full"
      >
        {translations.explorer_screen.confirm}
      </button>
    </PopupModal>
  );
};

const ExplorerScreen: React.FC = () => {
  const { translations } = useConfigStore(); // Access translations
  const [level, setLevel] = useState(0);
  const [coinBalance, setCoinBalance] = useState(0);
  const [acceleration, setAcceleration] = useState(0);
  const [popupUpdateWeight, setPopupUpdateWeight] =
    useState<IUpgradeWeight | null>(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const { data: kingOfShibaUserInfo, refetch } = useKingOfShibaUserInfo({
    variables: {},
  });
  const { data: upgradeWeightList } = useUpgradeWeightList();
  const { getUserName } = useAuthStore();

  useEffect(() => {
    if (kingOfShibaUserInfo) {
      setLevel(kingOfShibaUserInfo.level || 0);
      setCoinBalance(kingOfShibaUserInfo.coin_balance || 0);
      setAcceleration(kingOfShibaUserInfo.acceleration || 0);
    }
  }, [kingOfShibaUserInfo]);

  const { mutate } = useUpgradeWeight({
    onSuccess(data) {
      refetch();
      showToast(translations.explorer_screen.boots_successful, "success");
    },
    onError(error) {
      showToast(error.message, "error");
    },
  });

  return (
    <PhoneScreenLayout tailWindPdRem={0}>
      <div className="max-h-[90vh] flex flex-col">
        <TopUserProfile username={getUserName()} />
        <LogoAndBalance coinBalance={coinBalance} level={level} />
        <div className="bg-gradient-card w-full h-16 rounded-[16px] flex flex-col justify-center items-center py-1">
          <div className="text-center text-base flex">
            {translations.explorer_screen.acceleration} :{" "}
            {Number(acceleration).toFixed(8)}
            <img
              src={RocketIconPng}
              alt="Rocket Icon"
              className="h-4 mx-auto rotate-[300deg]"
            />
          </div>
          <p className="text-center text-sm">
            {translations.explorer_screen.acceleration_message}
          </p>
        </div>
        <div className="grid grid-cols-2 gap-2 p-2 mt-2 overflow-y-auto flex-grow scroll-co">
          {upgradeWeightList?.data.map((upgradeWeight) => (
            <div
              key={upgradeWeight.id}
              className={`bg-gradient-card rounded-[16px] shadow-lg text-white cursor-pointer ${
                !upgradeWeight.is_active ? "opacity-70 cursor-not-allowed" : ""
              }`}
              onClick={() => {
                if (upgradeWeight.is_active) {
                  setPopupUpdateWeight(upgradeWeight);
                  setPopupOpen(true);
                } else {
                  showToast(
                    translations.explorer_screen.require_level.replace(
                      "{level}",
                      upgradeWeight.require_level
                    ),
                    "error"
                  );
                }
              }}
            >
              <div className="flex justify-center items-center border-b-[1px] border-[#4A5696]">
                <div className="w-16 border-r-[1px] border-[#4A5696] flex justify-center items-center p-0.5">
                  <div className="size-1/2 p flex-col justify-center items-center">
                    <img
                      src={DogFacePng}
                      alt="Dog Icon"
                      className="box-20 mx-auto"
                    />
                    <div className="text-[#F0F900] text-xs">
                      LV.{upgradeWeight.require_level}
                    </div>
                  </div>
                </div>
                <div className="flex-col justify-center items-center w-full">
                  <div className="text-center text-sm">
                    {upgradeWeight.name}
                  </div>
                  <div className="text-center text-[#F0F900] text-xs flex w-full justify-center items-center">
                    {translations.explorer_screen.sell}:
                    <img
                      src={GameCoinLogoPng}
                      alt="Coin Logo"
                      className="size-5 mx-1"
                    />
                    <i> {upgradeWeight.price}</i>
                  </div>
                </div>
              </div>
              <div className="flex justify-center m-2">
                <img
                  src={upgradeWeight.image}
                  alt="Rocket Engine"
                  className="w-full min-h-20 min-w-20 max-w-[70%] max-h-[70%]"
                />
              </div>
              <div className="text-center text-yellow-400 mb-1 text-[0.8rem] border-t-[1px] border-[#4A5696]">
                {translations.explorer_screen.power_up}:{" "}
                <b>{upgradeWeight.reward_weight}</b>{" "}
                {translations.explorer_screen.weight}
              </div>
            </div>
          ))}
        </div>
        <NavBar />
      </div>
      <PowerUpModal
        open={popupOpen}
        updateWeight={popupUpdateWeight}
        onConfirm={() => {
          if (popupUpdateWeight) {
            mutate({ id: popupUpdateWeight.id });
          }
        }}
        onClose={() => setPopupOpen(false)}
      />
    </PhoneScreenLayout>
  );
};

export default ExplorerScreen;

import { createMutation, createQuery } from "react-query-kit";
import {
  checkIn,
  checkInInfo,
  getPlanetRewards,
  getRandomLuckyImages,
  getTransactionHistory,
  listFriend,
  listUpgradeWeight,
  openBox,
  openBoxInfo,
  playGame,
  pushLuckyImage,
  rank,
  requestPayment,
  upgradeWeight,
  userInfo,
} from "./request";
import {
  IFriend,
  IPaginationQuery,
  IRankInfo,
  IResponseCheckIn,
  IResponseCheckInInfo,
  IResponseLuckyImages,
  IResponseOpenBox,
  IResponseOpenBoxInfo,
  IResponsePagination,
  IResponsePaymentTransaction,
  IResponsePlanetReward,
  IResponsePushLuckyImage,
  IResponseTransactionHistory,
  IResponseUserInfo,
  IWeightsResponse,
} from "./types";

export const useKingOfShibaUserInfo = createQuery({
  queryKey: ["useKingOfShibaUserInfo"],
  fetcher: (variables: {}): Promise<IResponseUserInfo> => userInfo(),
  refetchInterval: 1000 * 5 * 60,
});

export const useKingOfShibaPlayGame = createMutation({
  mutationFn: playGame,
});

export const useListFriend = createQuery({
  queryKey: ["useListFriend"],
  fetcher: (
    variables: IPaginationQuery
  ): Promise<IResponsePagination<IFriend>> => listFriend(variables),
});

export const useUpgradeWeightList = createQuery({
  queryKey: ["useUpgradeWeightList"],
  fetcher: (variables: {}): Promise<IWeightsResponse> => listUpgradeWeight(),
});

export const useUpgradeWeight = createMutation({
  mutationFn: upgradeWeight,
});

export const useRank = createQuery({
  queryKey: ["useRank"],
  fetcher: (
    variables: IPaginationQuery
  ): Promise<IResponsePagination<IRankInfo>> => rank(variables),
});

export const useCheckIn = createMutation<IResponseCheckIn>({
  mutationFn: checkIn,
});

export const useCheckInInfo = createQuery({
  queryKey: ["useCheckInInfo"],
  fetcher: (variables: {}): Promise<IResponseCheckInInfo> => checkInInfo(),
});

export const useOpenBox = createMutation<IResponseOpenBox>({
  mutationFn: openBox,
});

export const useOpenBoxInfo = createQuery({
  queryKey: ["useOpenBoxInfo"],
  fetcher: (variables: {}): Promise<IResponseOpenBoxInfo> => openBoxInfo(),
});

export const useRandomLuckyImages = createQuery({
  queryKey: ["useRandomLuckyImages"],
  fetcher: (): Promise<IResponseLuckyImages> => getRandomLuckyImages(),
});

export const usePushLuckyImage = createMutation<
  IResponsePushLuckyImage,
  { number: number }
>({
  mutationFn: ({ number }) => pushLuckyImage(number),
});

export const usePlanetRewards = createQuery({
  queryKey: ["planetRewards"],
  fetcher: (): Promise<IResponsePlanetReward[]> => getPlanetRewards(),
});

// Request payment
export const useRequestPayment = createMutation<
  IResponsePaymentTransaction,
  { planet_reward_lucky_id: number }
>({
  mutationFn: ({ planet_reward_lucky_id }) =>
    requestPayment(planet_reward_lucky_id),
});

// Fetch transaction history
export const useTransactionHistory = createQuery({
  queryKey: ["transactionHistory"],
  fetcher: (): Promise<IResponseTransactionHistory[]> =>
    getTransactionHistory(),
});

import React, { ReactNode } from "react";

interface PhoneScreenLayoutProps {
  children: ReactNode;
  tailWindPdRem?: number;
}

const PhoneScreenLayout: React.FC<PhoneScreenLayoutProps> = ({
  children,
  tailWindPdRem = 5,
}) => {
  return (
    <div className="bg-gradient min-h-screen flex flex-col items-center justify-center">
      <div className="bg-star-layer text-white rounded-lg shadow-lg w-full h-5/6 max-w-phone-screen">
        <div
          style={{ paddingBottom: `${tailWindPdRem}rem` }}
          className="w-full px-4 min-h-lvh"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default PhoneScreenLayout;

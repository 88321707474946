import api from "@/services/apis/api";
import {
  ITelegramAuthLinkResponse,
  ITelegramVerificationResponse,
  ITelegramVerifyRequest,
} from "./types";

export const getTelegramAuthLink =
  async (): Promise<ITelegramAuthLinkResponse> => {
    const response: ITelegramAuthLinkResponse = await api.get(
      "/api/user/social/telegram/auth-link/"
    );
    return response;
  };

export const verifyTelegramToken = async (
  data: ITelegramVerifyRequest
): Promise<ITelegramVerificationResponse> => {
  const response: ITelegramVerificationResponse = await api.post(
    "/api/user/social/telegram/verify/",
    data
  );
  return response;
};

export const checkTelegramVerification =
  async (): Promise<ITelegramVerificationResponse> => {
    const response: ITelegramVerificationResponse = await api.get(
      "/api/user/social/telegram/is-verified/"
    );
    return response;
  };

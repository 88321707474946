import { ReactComponent as CopyLinkIcon } from "@/assets/icons/copy-icon.svg";
import GameCoinLogo from "@/assets/images/coin-icon.png";
import CrownImagePng from "@/assets/images/crown.png";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IRankInfo,
  useListFriend,
  useRank,
} from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore";
import { copyToClipboard } from "@/utils/copy";
import { formatUsername } from "@/utils/string";
import { ListFriend } from "@/pages/friend/components/ListFriend";

const FriendScreen: React.FC = () => {
  const { getUserName, ref_url } = useAuthStore();
  const { translations } = useConfigStore(); // Access translations from zustand store
  const navigate = useNavigate();
  const [refUrl, setRefUrl] = useState("");
  const [rankSwap, setRankSwap] = useState<IRankInfo[]>([]);
  const [rankIndex, setRankIndex] = useState<number[]>([]);

  const { data: top3invitedFriends, isFetching: top3IsFetching } =
    useListFriend({
      variables: {
        page: 1,
        per_page: 3,
      },
    });
  const { data: top3Rank, isFetching: top3RankFetching } = useRank({
    variables: {
      page: 1,
      per_page: 3,
    },
  });

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  useEffect(() => {
    setRefUrl(ref_url);
  }, [ref_url]);

  useEffect(() => {
    if (top3Rank?.data && top3Rank.data.length === 3) {
      let swappedData = [...top3Rank.data];
      swappedData = [swappedData[1], swappedData[0], swappedData[2]];
      setRankSwap(swappedData);
      setRankIndex([2, 1, 3]);
    } else {
      setRankSwap(top3Rank?.data || []);
    }
  }, [top3Rank]);

  return (
    <PhoneScreenLayout>
      <div className="max-w-md mx-auto">
        <TopUserProfile username={getUserName()} />

        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold mt-4">
            {translations.friend_screen.refer_friends}
          </h1>
          <p className="text-sm">{translations.friend_screen.bonus_message}</p>
        </div>

        <div className="bg-gradient-card p-2 rounded-[16px] mb-4 text-center flex-col items-center justify-between">
          <p className="text-lg font-semibold">
            {translations.friend_screen.invite_friend}
          </p>
          <div className="flex justify-center items-center">
            <img src={GameCoinLogo} alt="Coin Counter" className="w-8" />
            <p className="text-sm">
              <span className="text-yellow-300 text-base">
                {translations.friend_screen.bonus_normal}
              </span>
            </p>
          </div>
        </div>

        <div className="bg-gradient-card p-2 rounded-[16px] mb-4 text-center flex-col items-center justify-between">
          <p className="text-lg font-semibold">
            {translations.friend_screen.invite_friend_premium}
          </p>
          <div className="flex justify-center items-center">
            <img src={GameCoinLogo} alt="Coin Counter" className="w-8" />
            <p className="text-sm">
              <span className="text-yellow-300 text-base">
                {translations.friend_screen.bonus_premium}
              </span>
            </p>
          </div>
        </div>

        <div className="flex justify-between w-full">
          <a
            href={`https://t.me/share/url?url=${encodeURIComponent(
              refUrl
            )}&text=Join King Of Shiba`}
            className="p-2 h-[3rem] leading-[1.7rem] bg-gradient-button text-white font-bold hover:bg-blue-700 rounded-[16px] w-full mr-2 text-center text-base"
            role="button"
          >
            {translations.friend_screen.invite_friend}
          </a>
          <button className="p-2 h-[3rem] w-[3rem] bg-gradient-button text-white font-bold hover:bg-blue-700 rounded-[16px] flex justify-center items-center">
            <CopyLinkIcon
              className="w-6 h-6"
              onClick={() => {
                copyToClipboard(refUrl);
              }}
            />
          </button>
        </div>

        <div className="mb-4">
          <h2 className="text-base font-bold">
            {translations.friend_screen.list_of_invited}
          </h2>
          {top3IsFetching ? (
            <p className="text-base text-center py-5 text-gray-400">
              {translations.friend_screen.loading}
            </p>
          ) : (
            <div>
              {top3invitedFriends?.data.length === 0 ? (
                <div className="text-sm text-gray-400 h-20 flex justify-center items-center rounded-[16px] bg-gradient-card">
                  <p>{translations.friend_screen.no_invites}</p>
                </div>
              ) : (
                <ul className="text-sm">
                  {top3invitedFriends && (
                    <ListFriend friends={top3invitedFriends.data} />
                  )}
                </ul>
              )}
            </div>
          )}

          <div className="w-full flex justify-center text-[#FFDB17] m-1 hover:text-[#ffe555]">
            <button onClick={() => handleNavigation("/friend/list")}>
              {translations.friend_screen.view_more}
            </button>
          </div>
        </div>
        <div className="text-center mb-4">
          <h2 className="text-3xl font-bold text-[#FFDB17] my-4">
            {translations.friend_screen.rank}
          </h2>
          {top3RankFetching ? (
            <p className="text-base text-center py-5 text-gray-400">
              {translations.friend_screen.loading}
            </p>
          ) : (
            <div>
              {rankSwap.length === 0 ? (
                <div className="text-sm text-gray-400 h-20 flex justify-center items-center rounded-[16px] bg-gradient-card">
                  <p>{translations.friend_screen.no_rank}</p>
                </div>
              ) : (
                <div className="flex justify-center items-center pt-10 ">
                  {rankSwap?.map((rank, index) => (
                    <div
                      key={index}
                      className="text-center min-w-20 flex flex-col justify-center items-center"
                    >
                      <div
                        className={`border-yellow-300 rounded-full w-12 h-12 border-4 flex items-center justify-center mb-2 relative ${
                          index === 1 && "w-20 h-20"
                        }`}
                      >
                        {index === 1 && (
                          <img
                            src={CrownImagePng}
                            alt="CrownImagePng"
                            className="h-[3.1rem] absolute top-[-3rem]"
                          />
                        )}

                        <div
                          className={`absolute text-black bg-white bottom-[-1rem] rounded-full w-5 h-5 text-[0.8rem] flex justify-center items-center ${
                            index === 1 && "bg-yellow-300"
                          }`}
                        >
                          <nav> {rankIndex[index]}</nav>
                        </div>
                        <p className="text-xl font-bold">
                          {rank.username?.charAt(0) || "?"}
                        </p>
                      </div>
                      <p>{formatUsername(rank.username)}</p>
                      <p className="text-sm text-gray-300">
                        {translations.friend_screen.level} {rank.level}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        <button
          className="bg-gradient-button text-white font-bold px-4 rounded-[16px] w-full size-14"
          onClick={() => {
            handleNavigation("/friend/rank");
          }}
        >
          {translations.friend_screen.view_leaderboard}
        </button>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default FriendScreen;

import React, { useEffect, useRef, useState } from "react";
import config from "@/env";
import { useConfigStore } from "@/services/stores/useConfigStore";

const AudioPlayer: React.FC = () => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const { sound } = useConfigStore();

  const [currentTrackIndex, setCurrentTrackIndex] = useState<number>(
    () => Number(localStorage.getItem("currentTrackIndex")) || 0
  );
  const [isFocused, setIsFocused] = useState(true);

  const playAudio = () => {
    if (sound && isFocused && audioRef.current) {
      audioRef.current.play().catch((error) => {
        console.error("Playback error:", error);
      });
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    audio.src = config.URL_MUSICS[currentTrackIndex];
    audio.currentTime = Number(localStorage.getItem("currentTime")) || 0;
    playAudio();
    const handleTrackEnd = () => {
      localStorage.setItem("currentTime", String(0));
      setCurrentTrackIndex(
        (prevIndex) => (prevIndex + 1) % config.URL_MUSICS.length
      );
    };

    audio.addEventListener("ended", handleTrackEnd);

    return () => {
      audio.removeEventListener("ended", handleTrackEnd);
    };
  }, [currentTrackIndex, isFocused, sound]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (audioRef.current && !audioRef.current.paused) {
        localStorage.setItem(
          "currentTime",
          String(audioRef.current.currentTime)
        );
      }
    }, 3000); // Save every 3 seconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("currentTrackIndex", String(currentTrackIndex));
  }, [currentTrackIndex]);

  useEffect(() => {
    const handleFocus = () => {
      setIsFocused(true);
      if (audioRef.current?.paused) {
        playAudio();
      }
    };

    const handleBlur = () => {
      setIsFocused(false);
      if (audioRef.current && !audioRef.current.paused) {
        audioRef.current.pause();
      }
    };

    window.addEventListener("click", handleFocus);
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("click", handleFocus);
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, [sound]);

  return (
    <div hidden>
      <audio controls ref={audioRef}>
        Your browser does not support the audio element.
      </audio>
    </div>
  );
};

export default AudioPlayer;

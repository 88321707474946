import api from "@/services/apis/api";
import {
  IInstagramAuthLinkResponse,
  IInstagramVerifyRequest,
  IInstagramVerificationResponse,
} from "./types";

export const getInstagramAuthLink =
  async (): Promise<IInstagramAuthLinkResponse> => {
    const response: IInstagramAuthLinkResponse = await api.get(
      "/api/user/social/instagram/auth-link/"
    );
    return response;
  };

export const verifyInstagramToken = async (
  data: IInstagramVerifyRequest
): Promise<IInstagramVerificationResponse> => {
  const response: IInstagramVerificationResponse = await api.post(
    "/api/user/social/instagram/verify/",
    data
  );
  return response;
};

export const checkInstagramVerification =
  async (): Promise<IInstagramVerificationResponse> => {
    const response: IInstagramVerificationResponse = await api.get(
      "/api/user/social/instagram/is-verified/"
    );
    return response;
  };

import api from "@/services/apis/api";
import {
  IFriend,
  IPaginationQuery,
  IRankInfo,
  IResponseCheckIn,
  IResponseCheckInInfo,
  IResponseOpenBox,
  IResponseOpenBoxInfo,
  IResponsePagination,
  IResponsePlayGame,
  IResponseUserInfo,
  IUpgradeWeightParam,
  IWeightsResponse,
  IResponseLuckyImages,
  IResponsePushLuckyImage,
  IResponsePlanetReward,
  IResponsePaymentTransaction,
  IResponseTransactionHistory,
} from "./types";

export const userInfo = async (): Promise<IResponseUserInfo> => {
  const response: IResponseUserInfo = await api.get(
    "/api/game/king-of-shiba/user/info/"
  );
  return response;
};

export const playGame = async (): Promise<IResponsePlayGame> => {
  const response: IResponsePlayGame = await api.post(
    "/r/api/game/king-of-shiba/action/tap/",
    {}
  );
  return response;
};

export const listFriend = async (
  query: IPaginationQuery
): Promise<IResponsePagination<IFriend>> => {
  const response: IResponsePagination<IFriend> = await api.get(
    "/api/game/king-of-shiba/friends/",
    {
      params: query,
    }
  );
  return response;
};

export const listUpgradeWeight = async (): Promise<IWeightsResponse> => {
  const response: IWeightsResponse = await api.get(
    "/api/game/king-of-shiba/upgrade-weights/",
    {}
  );
  return response;
};

export const upgradeWeight = async (
  params: IUpgradeWeightParam
): Promise<IResponsePlayGame> => {
  const response: IResponsePlayGame = await api.post(
    `/api/game/king-of-shiba/upgrade-weights/${params.id}/buy/`,
    {}
  );
  return response;
};

export const rank = async (
  query: IPaginationQuery
): Promise<IResponsePagination<IRankInfo>> => {
  const response: IResponsePagination<IRankInfo> = await api.get(
    "/api/game/king-of-shiba/rank/",
    {
      params: query,
    }
  );
  return response;
};
export const checkIn = async (): Promise<IResponseCheckIn> => {
  const response: IResponseCheckIn = await api.post(
    "/api/game/king-of-shiba/daily-task/check-in/",
    {}
  );
  return response;
};

export const checkInInfo = async (): Promise<IResponseCheckInInfo> => {
  const response: IResponseCheckInInfo = await api.get(
    "/api/game/king-of-shiba/daily-task/check-in-info/",
    {}
  );
  return response;
};

export const openBox = async (): Promise<IResponseOpenBox> => {
  const response: IResponseOpenBox = await api.post(
    "/api/game/king-of-shiba/daily-task/open-box/",
    {}
  );
  return response;
};

export const openBoxInfo = async (): Promise<IResponseOpenBoxInfo> => {
  const response: IResponseOpenBoxInfo = await api.get(
    "/api/game/king-of-shiba/daily-task/open-box-info/",
    {}
  );
  return response;
};

export const getRandomLuckyImages = async (): Promise<IResponseLuckyImages> => {
  const response: IResponseLuckyImages = await api.get(
    "/r/api/game/king-of-shiba/random-lucky-images"
  );
  return response;
};

export const pushLuckyImage = async (
  index: number
): Promise<IResponsePushLuckyImage> => {
  const response: IResponsePushLuckyImage = await api.post(
    `/r/api/game/king-of-shiba/push/${index}`,
    {}
  );
  return response;
};

export const getPlanetRewards = async (): Promise<IResponsePlanetReward[]> => {
  const response: IResponsePlanetReward[] = await api.get(
    "/api/game/king-of-shiba/planet-reward-luckies/planet-rewards"
  );
  return response;
};

// Request payment for planet reward
export const requestPayment = async (
  planet_reward_lucky_id: number
): Promise<IResponsePaymentTransaction> => {
  const response: IResponsePaymentTransaction = await api.post(
    `/api/game/king-of-shiba/planet-reward-luckies/request-payment`,
    null,
    {
      params: { planet_reward_lucky_id },
    }
  );
  return response;
};

// Fetch transaction history
export const getTransactionHistory = async (): Promise<
  IResponseTransactionHistory[]
> => {
  const response: IResponseTransactionHistory[] = await api.get(
    "/api/game/king-of-shiba/planet-reward-luckies/transaction-history"
  );
  return response;
};

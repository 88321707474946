import create from "zustand";
import keyValue from "@/commons/key-value";
import { authLogin, authLogout, TelegramUser } from "@/services/apis/auth";

interface AuthState {
  isAuthenticated: boolean;
  ref_url: string;
  user: TelegramUser | null;
  setRefUrl: (ref_url: string) => void;
  loginMiniApp: (telegramInitData: string) => Promise<void>;
  logout: () => void;
  getUserName: () => string;
}

export const useAuthStore = create<AuthState>((set, get) => ({
  isAuthenticated: !!localStorage.getItem(keyValue.accessToken),
  ref_url: "",
  user: JSON.parse(localStorage.getItem(keyValue.user) || "null"),
  setRefUrl: (ref_url: string) => {
    set({ ref_url });
  },
  loginMiniApp: async (telegramInitData: string) => {
    try {
      const response = await authLogin({ telegramInitData });
      localStorage.setItem(keyValue.accessToken, response.token);
      localStorage.setItem(keyValue.user, JSON.stringify(response.user));
      set({ isAuthenticated: true, user: response.user });
    } catch (error) {
      console.error("Login failed:", error);
      throw error; // Re-throw the error so the calling function knows it failed
    }
  },
  logout: () => {
    authLogout();
    localStorage.removeItem(keyValue.accessToken);
    localStorage.removeItem(keyValue.user);
    set({ isAuthenticated: false, user: null });
  },
  getUserName: () => {
    const user = get().user;
    if (user?.first_name && user?.last_name) {
      return `${user.first_name} ${user.last_name}`;
    }
    return user?.username || "Unknown";
  },
}));

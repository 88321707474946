import { TonConnectUIProvider } from "@tonconnect/ui-react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Router from "./Router";
import { UrlMapping } from "./commons/url-mapping.common";
import AudioPlayer from "./components/AudioPlayer";

const manifestUrl =
  "https://kingofshiba.xyz/tonconnect/tonconnect-manifest.json";

const App: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      const webApp = window.Telegram.WebApp;
      webApp.ready();
      webApp.setHeaderColor("#4D66A1");

      const hasHistory = window.history.length > 1;
      if (hasHistory && location.pathname !== UrlMapping.home) {
        webApp.BackButton.show();
        const backButtonHandler = () => {
          console.log("Back button pressed");
          navigate(-1);
        };
        webApp.BackButton.onClick(backButtonHandler);
        return () => {
          webApp.BackButton.offClick(backButtonHandler);
          webApp.BackButton.hide();
        };
      } else {
        webApp.BackButton.hide();
      }
    } else {
      console.error("Telegram WebApp API not available");
    }
  }, [navigate, location]);

  return (
    <TonConnectUIProvider manifestUrl={manifestUrl}>
      <div className="App">
        <Router />
        <AudioPlayer />
        <ToastContainer />
      </div>
    </TonConnectUIProvider>
  );
};

export default App;

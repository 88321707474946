import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SocialName, UrlMapping } from "@/commons/url-mapping.common";
import { useVerifyGoogleToken } from "@/services/apis/user/google-service";
import showToast from "@/commons/toast.common";

const SocialAuthCallback: React.FC = () => {
  const { social } = useParams<{ social: string }>();
  const navigate = useNavigate();
  const { mutate: verifyGoogleToken } = useVerifyGoogleToken();

  const getGoogleAccessTokenFromHash = () => {
    const hash = window.location.hash.substring(1);
    const params = new URLSearchParams(hash);
    return params.get("access_token");
  };

  useEffect(() => {
    if (social === SocialName.Google || social === SocialName.YouTube) {
      const accessToken = getGoogleAccessTokenFromHash();

      if (accessToken) {
        verifyGoogleToken(
          { access_token: accessToken },
          {
            onSuccess: (data: any) => {
              if (data?.status === "success") {
                console.log("Google verification successful");
                showToast("Google verification successful", "success");
                navigate(`${UrlMapping.earn_social}/${SocialName.YouTube}`);
              } else {
                console.error("Google verification failed");
                showToast("Google verification failed", "error");
                navigate(`${UrlMapping.earn_social}/${SocialName.YouTube}`);
              }
            },
            onError: (error: any) => {
              console.error("Error verifying token:", error);
              showToast("Error verifying token", "error");
              navigate(`${UrlMapping.earn_social}/${SocialName.YouTube}`);
            },
          }
        );
      } else {
        console.error("Access token not found");
        showToast("Access token not found", "error");
        navigate(`${UrlMapping.earn_social}/${SocialName.YouTube}`);
      }
    }
  }, [social, navigate, verifyGoogleToken]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <h2 className="text-xl font-bold">Verifying your Google account...</h2>
    </div>
  );
};

export default SocialAuthCallback;

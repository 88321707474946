import { createMutation, createQuery } from "react-query-kit";
import {
  checkTwitterVerification,
  getTwitterAuthLink,
  verifyTwitterToken,
} from "./request";
import {
  ITwitterAuthLinkResponse,
  ITwitterVerificationResponse,
  ITwitterVerifyRequest,
} from "./types";

export const useTwitterAuthLink = createQuery({
  queryKey: ["useTwitterAuthLink"],
  fetcher: (): Promise<ITwitterAuthLinkResponse> => getTwitterAuthLink(),
});

export const useVerifyTwitterToken = createMutation<
  ITwitterVerificationResponse,
  ITwitterVerifyRequest
>({
  mutationFn: verifyTwitterToken,
});

export const useCheckTwitterVerification = createQuery({
  queryKey: ["useCheckTwitterVerification"],
  fetcher: (): Promise<ITwitterVerificationResponse> =>
    checkTwitterVerification(),
});

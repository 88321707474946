import { default as GameCoinLogoPng } from "@/assets/images/coin-icon.png";
import CrownImagePng from "@/assets/images/crown.png";
import GoldPointImagePng from "@/assets/images/rank-image/gold-point.png";
import ProfileImagePng from "@/assets/images/rank-image/profile.png";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { IRankInfo, useRank } from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import the store for translations
import React, { useCallback, useEffect, useState } from "react";

const ListRank: React.FC<{ ranks: IRankInfo[] }> = ({ ranks }) => {
  const { translations } = useConfigStore(); // Access translations

  return (
    <div>
      {ranks.length === 0 ? (
        <p className="text-sm text-gray-400 h-20 flex justify-center items-center rounded-[16px] bg-gradient-card">
          {translations.rank_screen.no_invites}
        </p>
      ) : (
        <ul className="text-sm">
          {ranks.map((rank, index) => (
            <li
              key={rank.user_id}
              className="bg-gradient-card flex py-1 justify-around items-center mt-1 rounded-[16px]"
            >
              <div className="flex justify-center items-center text-center text-base">
                {[0, 1, 2].includes(index) ? (
                  <div>
                    <img src={CrownImagePng} alt="Crown" className="w-8 mr-2" />
                  </div>
                ) : (
                  <div className="w-8 mr-2" />
                )}
                <div className="flex items-center ">
                  <b className="mr-1"> {index + 1}</b>
                  <div className="w-8 h-8 mr-2 relative">
                    <div
                      className="absolute bg-yellow-500 text-white text-xs rounded-full top-[50%] left-[50%] w-8 h-8"
                      style={{
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                    <div
                      className="absolute bg-violet-800 text-white text-xs rounded-full top-[50%] left-[50%] w-7 h-7"
                      style={{
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                    <img
                      src={ProfileImagePng}
                      alt="Profile"
                      className="absolute top-[50%] left-[50%] w-6 h-6"
                      style={{
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="text-base flex-1">
                {rank?.username || translations.rank_screen.unknown}
              </div>
              <div className="text-base flex-[2]">
                <div className="text-yellow-300 text-base flex justify-start items-center">
                  <img
                    src={GoldPointImagePng}
                    alt="Point Coin Logo"
                    className="size-5 mx-1"
                  />
                  <i> {Number(rank.experience_point).toFixed(0)}</i>{" "}
                </div>
                <div className="text-yellow-300 text-base flex justify-start items-center">
                  <img
                    src={GameCoinLogoPng}
                    alt="Coin Logo"
                    className="size-5 mx-1"
                  />
                  <i> {Number(rank.coin_balance).toFixed(2)}</i>{" "}
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const RankScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore(); // Access translations
  const [pageNumber, setPageNumber] = useState(1);
  const [ranks, setRanks] = useState<IRankInfo[]>([]);
  const { data, isFetching } = useRank({
    variables: {
      page: pageNumber,
      per_page: 8,
    },
  });

  useEffect(() => {
    return () => {
      setRanks([]);
      setPageNumber(1);
    };
  }, []);

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      if (data.data.length === 0) return;

      setRanks((prevRanks) => [...prevRanks, ...(data.data as IRankInfo[])]);
    }
  }, [data]);

  const incrementPage = useCallback(() => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }, []);

  useInfiniteScroll(incrementPage, isFetching, data?.has_next || false);

  return (
    <PhoneScreenLayout>
      <div className="max-w-md mx-auto">
        <TopUserProfile username={getUserName()} />
        <div className="text-center mb-4 flex justify-center items-center mt-4">
          <h1 className="text-4xl font-bold pr-4 text-[#FFDB17]">
            {translations.rank_screen.title}
          </h1>
          <img src={CrownImagePng} alt="Crown" className="w-auto h-10" />
        </div>
        <div className="bg-gradient-card w-full h-16 rounded-[16px] flex flex-col justify-center items-center">
          <div className="text-center text-base flex justify-center items-center">
            {translations.rank_screen.your_points}
            <b> {" ("}</b>
            <img
              src={GoldPointImagePng}
              alt="Point Coin Logo"
              className="size-5 mx-1"
            />
            <b> {`) : ${Number(data?.user?.experience_point).toFixed(0)}`}</b>
          </div>
          <p className="text-center text-sm">
            {translations.rank_screen.points_accumulate}
          </p>
        </div>

        <div className="mb-4">
          <h2 className="text-base font-bold">
            {translations.rank_screen.leaderboard}
          </h2>
          {isFetching && pageNumber === 1 ? (
            <p className="text-base text-center py-5 text-gray-400">
              {translations.rank_screen.loading}
            </p>
          ) : (
            <ListRank ranks={ranks} />
          )}
          {isFetching && pageNumber !== 1 && (
            <p className="text-center">
              {translations.rank_screen.loading_more}
            </p>
          )}
        </div>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default RankScreen;

import React, { useEffect, useRef, useState } from "react";
import ShibaKingPng from "@/assets/images/home-screen/shiba-king.png";
import showToast from "@/commons/toast.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import LogoAndBalance from "@/components/logo-and-balance/LogoAndBalance";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import {
  ResponseLuckyImage,
  useKingOfShibaPlayGame,
  useKingOfShibaUserInfo,
  usePushLuckyImage,
  useRandomLuckyImages,
} from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore";
import LoadingSkeleton from "@/components/skeleton/LoadingSkeleton";

interface PlusOne {
  id: number;
  x: number;
  y: number;
}

const HomeScreen: React.FC = () => {
  const { getUserName, setRefUrl } = useAuthStore();
  const { translations, vibrate } = useConfigStore();
  const [boxes, setBoxes] = useState<ResponseLuckyImage[]>([]);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [countdownPending, setCountdownPending] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [level, setLevel] = useState(0);
  const [coinBalance, setCoinBalance] = useState(0);
  const [plusOnes, setPlusOnes] = useState<PlusOne[]>([]);
  const imgRef = useRef<HTMLImageElement>(null);
  const [touchStarted, setTouchStarted] = useState(false);

  const { data: kingOfShibaUserInfo } = useKingOfShibaUserInfo({
    variables: {},
  });

  const { mutate: mutatePlayGame } = useKingOfShibaPlayGame({
    onSuccess(data) {
      setCoinBalance(data?.coin_balance || 0);
    },
  });

  const { data: randomLuckyImages, refetch } = useRandomLuckyImages();
  const { mutate: pushLuckyImage, isPending: isPendingPushLuckyImage } =
    usePushLuckyImage({
      onError(error) {
        showToast(error.message, "error");
      },
      onSuccess(data) {
        showToast(data.message, "success");
      },
    });

  const handleGameTap = (event: React.TouchEvent | React.MouseEvent) => {
    mutatePlayGame();

    if (navigator.vibrate && vibrate) {
      navigator.vibrate(200);
    }

    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      const newPlusOnes: PlusOne[] = [];

      if ("touches" in event) {
        for (let i = 0; i < event.touches.length; i++) {
          const touch = event.touches[i];
          const x = touch.clientX - rect.left;
          const y = touch.clientY - rect.top;
          const id = Date.now() + i;
          newPlusOnes.push({ id, x, y });
        }
      } else {
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        const id = Date.now();
        newPlusOnes.push({ id, x, y });
      }

      setPlusOnes((prevPlusOnes) => [...prevPlusOnes, ...newPlusOnes]);

      newPlusOnes.forEach((plusOne) => {
        setTimeout(() => {
          setPlusOnes((prevPlusOnes) =>
            prevPlusOnes.filter((po) => po.id !== plusOne.id)
          );
        }, 1000);
      });

      if (imgRef.current) {
        imgRef.current.classList.add("vibrate");

        setTimeout(() => {
          if (imgRef.current) {
            imgRef.current.classList.remove("vibrate");
          }
        }, 500);
      }
    }
  };

  const handleTouchStart = (event: React.TouchEvent) => {
    setTouchStarted(true);
    handleGameTap(event);
  };

  const handleClick = (event: React.MouseEvent) => {
    if (!touchStarted) {
      handleGameTap(event);
    }
    setTouchStarted(false);
  };

  const handleBoxClick = (index: number) => {
    setCountdownPending(true);
    pushLuckyImage({ number: index });
  };

  useEffect(() => {
    if (randomLuckyImages?.selected_images) {
      setCountdownPending(false);
      setBoxes(randomLuckyImages.selected_images);
    }
    const intervalId = setInterval(() => {
      if (
        randomLuckyImages?.time_created &&
        randomLuckyImages?.expiry_seconds
      ) {
        const now = Math.floor(Date.now() / 1000); // Current time in seconds
        const timeCreated = randomLuckyImages.time_created;
        const expirySeconds = randomLuckyImages.expiry_seconds;
        const timeLeft = timeCreated + expirySeconds - now;

        if (timeLeft <= 0) {
          refetch();
        } else {
          setCountdown(timeLeft);
        }
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [randomLuckyImages, refetch]);

  useEffect(() => {
    setLevel(kingOfShibaUserInfo?.level || 0);
    setCoinBalance(kingOfShibaUserInfo?.coin_balance || 0);
    setRefUrl(kingOfShibaUserInfo?.ref_url || "");
  }, [kingOfShibaUserInfo, setRefUrl]);

  useEffect(() => {
    if (randomLuckyImages?.messages || [].length > 0) {
      for (const message of randomLuckyImages?.messages || []) {
        showToast(message.message, "info");
      }
    }
  }, [randomLuckyImages]);

  return (
    <PhoneScreenLayout tailWindPdRem={1}>
      <div>
        <TopUserProfile username={getUserName()} />
        <LogoAndBalance coinBalance={coinBalance} level={level} />
        <div className="min-h-[180px] w-full relative" ref={containerRef}>
          <div className="relative">
            {(isPendingPushLuckyImage || countdownPending) && (
              <LoadingSkeleton
                message={translations.home_screen.waiting_for_roll}
              ></LoadingSkeleton>
            )}
            <div className="flex flex-wrap justify-center items-center mt-3">
              {boxes.map((luckyImage) => (
                <div
                  key={luckyImage.index}
                  className="bg-gradient-card border-2 border-white rounded-md w-[20%] m-1 z-20 aspect-square"
                  onClick={() => handleBoxClick(luckyImage.index)}
                >
                  <img
                    src={luckyImage.url_image}
                    alt={`Box ${luckyImage.index}`}
                    className="w-full h-full object-cover rounded-md"
                  />
                </div>
              ))}
            </div>
          </div>

          {countdown !== null && (
            <div className="text-center text-red-100 font-bold text-xs mb-2">
              {translations.home_screen.lucky_images_expire}: <b>{countdown}</b>{" "}
              seconds
            </div>
          )}
          {plusOnes.map((plusOne) => (
            <div
              key={plusOne.id}
              className="absolute text-white font-bold text-3xl"
              style={{
                left: plusOne.x,
                top: plusOne.y,
                animation: "fly-up 1s ease-out forwards",
              }}
            >
              +1
            </div>
          ))}
        </div>

        <div className="text-center w-full mt-2 z-10">
          <div
            className="flex justify-center items-center"
            style={{
              bottom: -50,
            }}
          >
            <img
              src={ShibaKingPng}
              alt="Shiba King"
              className="w-full"
              onTouchStart={handleTouchStart}
              onClick={handleClick}
              ref={imgRef}
            />
          </div>
        </div>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default HomeScreen;

import { createMutation, createQuery } from "react-query-kit";
import {
  checkFacebookVerification,
  getFacebookAuthLink,
  verifyFacebookToken,
} from "./request";
import {
  IFacebookAuthLinkResponse,
  IFacebookVerificationResponse,
  IFacebookVerifyRequest,
} from "./types";

export const useFacebookAuthLink = createQuery({
  queryKey: ["useFacebookAuthLink"],
  fetcher: (): Promise<IFacebookAuthLinkResponse> => getFacebookAuthLink(),
});

export const useVerifyFacebookToken = createMutation<
  IFacebookVerificationResponse,
  IFacebookVerifyRequest
>({
  mutationFn: verifyFacebookToken,
});

export const useCheckFacebookVerification = createQuery({
  queryKey: ["useCheckFacebookVerification"],
  fetcher: (): Promise<IFacebookVerificationResponse> =>
    checkFacebookVerification(),
});

import React from "react";

interface LoadingSkeletonProps {
  message: string;
  children?: React.ReactNode;
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  message,
  children,
}) => {
  return (
    <div className="absolute inset-0 bg-black bg-opacity-50 z-30 flex flex-col justify-center items-center rounded-sm">
      <div className="flex flex-col justify-center items-center space-y-4">
        <div className="w-12 h-12 border-2 border-t-transparent border-b-transparent border-l-transparent rounded-full animate-spin bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 p-1">
          <div className="w-full h-full bg-yellow-300 rounded-full"></div>
        </div>
        <div className="text-white text-2xl font-bold">{message}</div>
      </div>
      {children && <div className="children-wrapper mt-4">{children}</div>}
    </div>
  );
};

export default LoadingSkeleton;

import api from "@/services/apis/api";
import {
  ITwitterAuthLinkResponse,
  ITwitterVerifyRequest,
  ITwitterVerificationResponse,
} from "./types";

export const getTwitterAuthLink =
  async (): Promise<ITwitterAuthLinkResponse> => {
    const response: ITwitterAuthLinkResponse = await api.get(
      "/api/user/social/twitter/auth-link/"
    );
    return response;
  };

export const verifyTwitterToken = async (
  data: ITwitterVerifyRequest
): Promise<ITwitterVerificationResponse> => {
  const response: ITwitterVerificationResponse = await api.post(
    "/api/user/social/twitter/verify/",
    data
  );
  return response;
};

export const checkTwitterVerification =
  async (): Promise<ITwitterVerificationResponse> => {
    const response: ITwitterVerificationResponse = await api.get(
      "/api/user/social/twitter/is-verified/"
    );
    return response;
  };

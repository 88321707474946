import WalletPng from "@/assets/images/airdrop-screen/wallet.png";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import config from "@/env";
import { useTonConnect } from "@/hooks/useTonConnect";
import { useTonConnectionStatus } from "@/hooks/useTonConnectionStatus";
import { useTransaction } from "@/hooks/useTransaction";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore";
import { shortenAddress } from "@/utils/transaction_string";
import { beginCell } from "@ton/ton";
import React from "react";

const AirdropScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore();

  const {
    wallet,
    userFriendlyAddress,
    connectWallet,
    disconnectWallet,
    sendTransaction: tonSendTransaction,
    isConnected,
    isLoading,
  } = useTonConnect();

  const { connectionRestored } = useTonConnectionStatus();

  const { isPending, isSuccess, error, transactionId, sendTransaction } =
    useTransaction();
  const { user } = useAuthStore();

  if (!connectionRestored) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-blue-900 to-indigo-900">
        <div className="text-lg font-semibold text-white">
          {translations.airdrop_screen.loading}
        </div>
      </div>
    );
  }
  const body = beginCell()
    .storeUint(0, 32)
    .storeStringTail(`kingofshiba.xyz_${user?.user_id}`)
    .endCell();
  const transaction = {
    messages: [
      {
        address: config.AIRDROP_WALLET_ADDRESS,
        amount: "20000000",
        payload: body.toBoc().toString("base64"),
      },
    ],
    validUntil: Math.floor(Date.now() / 1000) + 3600,
  };

  const handleSendTransaction = async () => {
    await sendTransaction(transaction, tonSendTransaction);
  };

  return (
    <PhoneScreenLayout>
      <div className="max-w-md mx-auto">
        <TopUserProfile username={getUserName()} />

        <div className="text-center mb-6 mt-10">
          <img
            src={WalletPng}
            alt={translations.airdrop_screen.wallet_image_alt}
            className="mx-auto w-28 h-28 mb-4"
          />
          <h1 className="text-3xl font-bold text-white mb-2">
            {translations.airdrop_screen.connect_wallet}
          </h1>
          <p className="text-white text-sm mb-4">
            {translations.airdrop_screen.wallet_info}
          </p>
        </div>

        <div className="mb-8">
          {!isConnected ? (
            <button
              onClick={connectWallet}
              disabled={isLoading || isPending}
              className={`${
                isLoading || isPending
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-700 hover:to-purple-700"
              } text-white px-6 py-3 rounded-full w-full mb-4`}
            >
              {isLoading || isPending
                ? translations.airdrop_screen.connecting
                : translations.airdrop_screen.connect_wallet_button}
            </button>
          ) : (
            <button
              onClick={disconnectWallet}
              disabled={isLoading || isPending}
              className={`${
                isLoading || isPending
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-gradient-to-r from-red-600 to-pink-600 hover:from-red-700 hover:to-pink-700"
              } text-white px-6 py-3 rounded-full w-full mb-4`}
            >
              {isLoading || isPending
                ? translations.airdrop_screen.disconnecting
                : translations.airdrop_screen.disconnect_wallet_button}
            </button>
          )}
        </div>

        {/* Display wallet information if connected */}
        {isConnected && (
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-xl font-semibold text-gray-800 mb-2">
              {translations.airdrop_screen.connected_wallet}
            </h2>
            <p className="text-gray-600 mb-2">
              <span className="font-semibold">
                {translations.airdrop_screen.address}:
              </span>{" "}
              {shortenAddress(userFriendlyAddress)}
            </p>
            <p className="text-gray-600 mb-4">
              <span className="font-semibold">
                {translations.airdrop_screen.device}:
              </span>{" "}
              {wallet?.device.appName}
            </p>

            {error && (
              <p className="text-red-500 mb-4">
                {translations.airdrop_screen.error}: {error}
              </p>
            )}
            {isSuccess && (
              <p className="text-green-500 mb-4">
                {translations.airdrop_screen.transaction_success}
              </p>
            )}

            <button
              onClick={handleSendTransaction}
              disabled={isPending}
              className={`${
                isPending
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700"
              } text-white px-6 py-3 w-full rounded-[16px] transition duration-300`}
            >
              {isPending
                ? translations.airdrop_screen.sending
                : translations.airdrop_screen.confirm}
            </button>

            {/* Transaction link to tonviewer */}
            {isSuccess && transactionId && (
              <div className="mt-4">
                <a
                  href={`${config.EXPLORER_SCAN}/transaction/${transactionId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:text-blue-700 underline"
                >
                  {translations.airdrop_screen.view_transaction}
                </a>
              </div>
            )}
          </div>
        )}
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default AirdropScreen;

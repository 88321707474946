import create from "zustand";
import en from "@/locales/en.json"; // default fallback language
import vi from "@/locales/vi.json";
import zh from "@/locales/zh.json";
import es from "@/locales/es.json";
import pt from "@/locales/pt.json";
import id from "@/locales/id.json";
import fr from "@/locales/fr.json";
import ja from "@/locales/ja.json";
import ru from "@/locales/ru.json";
import de from "@/locales/de.json";
import ko from "@/locales/ko.json";
import it from "@/locales/it.json";
import tr from "@/locales/tr.json";
import hi from "@/locales/hi.json";

// Define the interface for your configuration state
interface ConfigState {
  language: string;
  translations: Record<string, any>;
  vibrate: boolean;
  sound: boolean;
  setLanguage: (language: string) => void;
  toggleVibrate: () => void;
  toggleSound: () => void;
  loadConfig: () => void;
  saveConfig: () => void;
}

// Utility function to load config from localStorage
const loadConfigFromLocalStorage = (): Omit<
  ConfigState,
  | "setLanguage"
  | "toggleVibrate"
  | "toggleSound"
  | "loadConfig"
  | "saveConfig"
  | "translations"
> => {
  const config = localStorage.getItem("gameSettings");
  if (config) {
    return JSON.parse(config);
  }
  return {
    language: "en", // default language
    vibrate: false,
    sound: true,
  };
};

// Function to load translations based on selected language
const loadTranslations = (language: string): Record<string, any> => {
  switch (language) {
    case "vi":
      return vi;
    case "zh":
      return zh;
    case "es":
      return es;
    case "pt":
      return pt;
    case "id":
      return id;
    case "fr":
      return fr;
    case "ja":
      return ja;
    case "ru":
      return ru;
    case "de":
      return de;
    case "ko":
      return ko;
    case "it":
      return it;
    case "tr":
      return tr;
    case "hi":
      return hi;
    case "en":
    default:
      return en;
  }
};

export const useConfigStore = create<ConfigState>((set, get) => ({
  ...loadConfigFromLocalStorage(), // Load initial state from localStorage or use defaults
  translations: loadTranslations(loadConfigFromLocalStorage().language), // Load translations based on saved language

  // Set the language and update translations
  setLanguage: (language: string) => {
    set({ language, translations: loadTranslations(language) });
    get().saveConfig(); // Persist to localStorage whenever it changes
  },

  // Toggle vibration setting
  toggleVibrate: () => {
    set((state) => ({ vibrate: !state.vibrate }));
    get().saveConfig(); // Persist to localStorage whenever it changes
  },

  // Toggle sound setting
  toggleSound: () => {
    set((state) => ({ sound: !state.sound }));
    get().saveConfig(); // Persist to localStorage whenever it changes
  },

  // Load configuration from localStorage
  loadConfig: () => {
    const config = loadConfigFromLocalStorage();
    set({ ...config, translations: loadTranslations(config.language) });
  },

  // Save the configuration to localStorage
  saveConfig: () => {
    const { language, vibrate, sound } = get();
    const config = { language, vibrate, sound };
    localStorage.setItem("gameSettings", JSON.stringify(config));
  },
}));

import { createMutation, createQuery } from "react-query-kit";
import {
  checkGoogleVerification,
  getGoogleAuthLink,
  verifyGoogleToken,
} from "./request";
import {
  IGoogleAuthLinkResponse,
  IGoogleVerificationResponse,
  IGoogleVerifyRequest,
} from "./types";

export const useGoogleAuthLink = createQuery({
  queryKey: ["useGoogleAuthLink"],
  fetcher: (): Promise<IGoogleAuthLinkResponse> => getGoogleAuthLink(),
});

export const useVerifyGoogleToken = createMutation<
  IGoogleVerificationResponse,
  IGoogleVerifyRequest
>({
  mutationFn: verifyGoogleToken,
});

export const useCheckGoogleVerification = createQuery({
  queryKey: ["useCheckGoogleVerification"],
  fetcher: (): Promise<IGoogleVerificationResponse> =>
    checkGoogleVerification(),
});

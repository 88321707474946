import { createMutation, createQuery } from "react-query-kit";
import {
  checkTikTokVerification,
  getTikTokAuthLink,
  verifyTikTokToken,
} from "./request";
import {
  ITikTokAuthLinkResponse,
  ITikTokVerificationResponse,
  ITikTokVerifyRequest,
} from "./types";

export const useTikTokAuthLink = createQuery({
  queryKey: ["useTikTokAuthLink"],
  fetcher: (): Promise<ITikTokAuthLinkResponse> => getTikTokAuthLink(),
});

export const useVerifyTikTokToken = createMutation<
  ITikTokVerificationResponse,
  ITikTokVerifyRequest
>({
  mutationFn: verifyTikTokToken,
});

export const useCheckTikTokVerification = createQuery({
  queryKey: ["useCheckTikTokVerification"],
  fetcher: (): Promise<ITikTokVerificationResponse> =>
    checkTikTokVerification(),
});

import { handleErrorMessage } from "@/commons/error-handel";
import showToast from "@/commons/toast.common";
import { UrlMapping } from "@/commons/url-mapping.common";
import { useAuthStore } from "@/services/stores/useAuthStore";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

declare global {
  interface Window {
    Telegram: any;
  }
}

const mockToken =
  "query_id=AAHk73c9AAAAAOTvdz30vp2v&user=%7B%22id%22%3A1031270372%2C%22first_name%22%3A%22Nguy%E1%BB%85n%22%2C%22last_name%22%3A%22Duy%20S%E1%BB%B9%22%2C%22username%22%3A%22duyduysysy%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1730015425&hash=32c22020b090f649fc7f5ec307b071dfd26d6df2b39052adb0d8826579bf9c83";

const Login: React.FC = () => {
  const { loginMiniApp, isAuthenticated, logout } = useAuthStore();
  const navigate = useNavigate();
  useEffect(() => {
    logout();
  }, []);
  useEffect(() => {
    const handleDOMContentLoaded = async () => {
      if (window.Telegram && window.Telegram.WebApp) {
        const telegramInitData = window.Telegram.WebApp.initData || mockToken;
        try {
          if (!isAuthenticated) {
            await loginMiniApp(telegramInitData);
          }
          navigate(UrlMapping.launch);
        } catch (error: any) {
          handleErrorMessage(error, "Cannot authenticate");
        }
      } else {
        showToast("Telegram WebApp not found");
      }
    };

    handleDOMContentLoaded();
  }, [isAuthenticated, loginMiniApp, navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-gradient-to-r from-blue-500 to-purple-600">
      <div className="bg-black bg-opacity-50 p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-2xl font-bold text-white">Login with Telegram</h1>
      </div>
    </div>
  );
};

export default Login;

import GameTitleImagePng from "@/assets/images/home-screen/game-title.png";
import React, { useEffect, useRef, useState } from "react";

import GameCoinLogoPng from "@/assets/images/coin-icon.png";
import GameRocketLevelPng from "@/assets/images/home-screen/rocket-level.png";
type Props = {
  coinBalance?: number;
  level?: number;
};

const LogoAndBalance: React.FC<Props> = (props: Props) => {
  const levelBarRef = useRef<HTMLDivElement>(null);
  const rocketRef = useRef<HTMLDivElement>(null);
  const [level, setLevel] = useState(0);
  const [levelBarWidth, setLevelBarWidth] = useState(0);
  const [coinBalance, setCoinBalance] = useState(0);
  const [rocketWidth, setRocketWidth] = useState(0);
  useEffect(() => {
    if (levelBarRef.current) {
      setLevelBarWidth(levelBarRef.current.offsetWidth);
    }
    if (rocketRef.current) {
      setRocketWidth(rocketRef.current.offsetWidth);
    }
    if (props?.coinBalance) {
      setCoinBalance(props.coinBalance);
      setLevel(Number(props.level));
    }
  }, [props]);

  const calculateRocketPosition = () => {
    const maxRocketPosition = levelBarWidth - rocketWidth;
    const width = (maxRocketPosition * level) / 100;
    return Math.min(width, maxRocketPosition);
  };

  return (
    <div className="text-center">
      <div className="text-center flex flex-col items-center justify-center w-full">
        <div className="flex justify-center items-center">
          <img src={GameTitleImagePng} alt="Home Title" className="w-full" />
        </div>
      </div>

      <div className="text-center w-full flex flex-col items-center justify-center pt-2">
        <div
          className="flex flex-col items-center justify-center w-52 h-0.5 bg-[#EACDFB] relative"
          ref={levelBarRef}
        >
          <div
            className="flex justify-center items-center absolute"
            style={{
              left: calculateRocketPosition(),
            }}
          >
            <div className="relative" ref={rocketRef}>
              <div
                className="text-[#ECF407] text-xs absolute"
                style={{
                  bottom: 25,
                  right: 0,
                }}
              >
                LV.{level}
              </div>
              <img
                src={GameRocketLevelPng}
                alt="Rocket level"
                className="w-14"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="text-center flex flex-col items-center justify-center w-full pt-2 font-rubik italic">
        <div className="flex justify-center items-center">
          <img src={GameCoinLogoPng} alt="Coin Counter" className="w-12" />
          <nav className="text-[#F0F900] text-2xl ml-1">
            {Number(coinBalance).toFixed(1)}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default LogoAndBalance;

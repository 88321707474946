import React from "react";
import { Route, Routes } from "react-router-dom";
import { UrlMapping } from "./commons/url-mapping.common";
import ProtectedRoute from "./components/ProtectedRoute";
import AirdropScreen from "./pages/airdrop";
import Login from "./pages/auth/login";
import EarnScreen from "./pages/earn";
import DailyGameDetailScreen from "./pages/earn/daily-game";
import DailyTaskDetailScreen from "./pages/earn/daily-task";
import EnergyCollectionScreen from "./pages/earn/energy-collection";
import SocialTaskScreen from "./pages/earn/social";
import ExplorerScreen from "./pages/explorer";
import FriendScreen from "./pages/friend";
import ListFriendScreen from "./pages/friend/list-friend";
import RankScreen from "./pages/friend/rank";
import HomeScreen from "./pages/home";
import LaunchScreen from "./pages/launch";
import NotFoundPage from "./pages/NotFoundPage";
import SettingsScreen from "./pages/setting";
import SocialAuthCallback from "./pages/SocialAuthCallback";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path={UrlMapping.auth_login} element={<Login />} />
      <Route
        path={UrlMapping.launch}
        element={<ProtectedRoute component={LaunchScreen} />}
      />
      <Route
        path={UrlMapping.home}
        element={<ProtectedRoute component={HomeScreen} />}
      />
      <Route
        path={UrlMapping.explorer}
        element={<ProtectedRoute component={ExplorerScreen} />}
      />
      <Route
        path={UrlMapping.airdrop}
        element={<ProtectedRoute component={AirdropScreen} />}
      />

      {/* ----------------------------earn---------------------------- */}
      <Route
        path={UrlMapping.earn}
        element={<ProtectedRoute component={EarnScreen} />}
      />
      <Route
        path={`${UrlMapping.earn}/daily-task`}
        element={<ProtectedRoute component={DailyTaskDetailScreen} />}
      />
      <Route
        path={`${UrlMapping.earn}/daily-game`}
        element={<ProtectedRoute component={DailyGameDetailScreen} />}
      />
      <Route
        path={`${UrlMapping.earn}/energy-collection`}
        element={<ProtectedRoute component={EnergyCollectionScreen} />}
      />
      {/* social */}
      <Route
        path={`${UrlMapping.earn_social}/:social`}
        element={<ProtectedRoute component={SocialTaskScreen} />}
      />
      {/* --------------------------friend------------------------------ */}
      <Route
        path={UrlMapping.friend}
        element={<ProtectedRoute component={FriendScreen} />}
      />
      <Route
        path={`${UrlMapping.friend}/list`}
        element={<ProtectedRoute component={ListFriendScreen} />}
      />
      <Route
        path={`${UrlMapping.friend}/rank`}
        element={<ProtectedRoute component={RankScreen} />}
      />
      <Route path="*" element={<NotFoundPage />} />

      {/* --------------------------friend------------------------------ */}

      <Route
        path={`${UrlMapping.auth_social_callback}/:social`}
        element={<ProtectedRoute component={SocialAuthCallback} />}
      />
      <Route
        path={`${UrlMapping.setting}`}
        element={<ProtectedRoute component={SettingsScreen} />}
      />
    </Routes>
  );
};

export default Router;

// eslint-disable-next-line import/no-anonymous-default-export
export enum UrlMapping {
  auth_login = "/login",
  launch = "/",
  home = "/home",
  explorer = "/explorer",
  friend = "/friend",
  earn = "/earn",
  earn_social = "/earn/social",
  airdrop = "/airdrop",
  auth_social_callback = "/verify/callback",
  setting = "/setting",
}

export enum SocialName {
  Facebook = "facebook",
  YouTube = "youtube",
  Google = "google",
  Telegram = "telegram",
  Twitter = "twitter",
  TikTok = "tikTok",
  Instagram = "Instagram",
}

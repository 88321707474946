import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import useInfiniteScroll from "@/hooks/useInfiniteScroll";
import { IFriend, useListFriend } from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import for translations
import React, { useCallback, useEffect, useState } from "react";
import { ListFriend } from "@/pages/friend/components/ListFriend";

const ListFriendScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore(); // Access translations
  const [pageNumber, setPageNumber] = useState(1);
  const [friends, setFriends] = useState<IFriend[]>([]);

  useEffect(() => {
    return () => {
      setFriends([]);
      setPageNumber(1);
    };
  }, []);

  const { data, isFetching } = useListFriend({
    variables: {
      page: pageNumber,
      per_page: 8,
    },
  });

  useEffect(() => {
    if (data?.data && Array.isArray(data.data)) {
      if (data.data.length === 0) return;

      setFriends((prevFriends) => [
        ...prevFriends,
        ...(data.data as IFriend[]),
      ]);
    }
  }, [data]);

  const incrementPage = useCallback(() => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  }, []);

  useInfiniteScroll(incrementPage, isFetching, data?.has_next || false);

  return (
    <PhoneScreenLayout>
      <div className="max-w-md mx-auto">
        <TopUserProfile username={getUserName()} />
        <div className="text-center mb-4">
          <h1 className="text-2xl font-bold mt-4">
            {translations.list_friend_screen.refer_friends}
          </h1>
          <p className="text-sm">
            {translations.list_friend_screen.bonus_message}
          </p>
        </div>
        <div className="mb-4">
          <h2 className="text-base font-bold">
            {translations.list_friend_screen.invited_friends_list}
          </h2>
          {isFetching && pageNumber === 1 ? (
            <p className="text-base text-center py-5 text-gray-400">
              {translations.list_friend_screen.loading}
            </p>
          ) : (
            <ListFriend friends={friends} />
          )}
          {isFetching && pageNumber !== 1 && (
            <p className="text-center">
              {translations.list_friend_screen.loading_more}
            </p>
          )}
        </div>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default ListFriendScreen;

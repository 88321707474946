import { useState } from "react";
import TonWeb from "tonweb";

export const useTransaction = () => {
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [transactionId, setTransactionId] = useState<string | null>(null);
  const getTransactionHashFromBoc = async (boc: string): Promise<string> => {
    try {
      const bocBytes = TonWeb.utils.base64ToBytes(boc);
      const cells = TonWeb.boc.Cell.oneFromBoc(bocBytes);
      const transactionHash = TonWeb.utils.bytesToHex(await cells.hash());
      return transactionHash;
    } catch (error) {
      console.error(
        "Error parsing BOC or calculating transaction hash:",
        error
      );
      throw new Error("Failed to extract transaction hash from BOC");
    }
  };
  const sendTransaction = async (
    transaction: any,
    sendTransactionFunction: (transaction: any) => Promise<any>
  ) => {
    try {
      setIsPending(true);
      setError(null);
      setIsSuccess(false);
      setTransactionId(null);

      const result = await sendTransactionFunction(transaction);
      const boc = result.boc;

      if (boc) {
        const transactionHash = await getTransactionHashFromBoc(boc);
        setTransactionId(transactionHash);
      } else {
        setTransactionId(result.transactionId || result.txHash || "");
      }

      setIsSuccess(true);
    } catch (err: any) {
      console.error("Transaction failed:", err);
      setError(err.message || "Transaction failed");
      setIsSuccess(false);
    } finally {
      setIsPending(false);
    }
  };

  // Function to reset the transaction state
  const resetTransactionState = () => {
    setIsPending(false);
    setIsSuccess(false);
    setError(null);
    setTransactionId(null);
  };

  return {
    isPending,
    isSuccess,
    error,
    transactionId,
    sendTransaction,
    resetTransactionState,
  };
};

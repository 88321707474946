import CirclePng from "@/assets/images/launch-screen/circle.png";
import LogoPng from "@/assets/images/launch-screen/logo.png";
import PlanetPng from "@/assets/images/launch-screen/planet.png";
import RocketPng from "@/assets/images/launch-screen/rocket.png";
import ShibaPng from "@/assets/images/launch-screen/shiba.png";
import { UrlMapping } from "@/commons/url-mapping.common";
import { useConfigStore } from "@/services/stores/useConfigStore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const importAll = (r: __WebpackModuleApi.RequireContext): string[] =>
  r.keys().map(r);

const images: string[] = importAll(
  require.context("@/assets", true, /\.(png|jpe?g|svg)$/)
);

interface LaunchScreenProps {}

const LaunchScreen: React.FC<LaunchScreenProps> = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadedCount, setLoadedCount] = useState(0);

  // Access translations from the zustand store
  const { translations } = useConfigStore();

  const handleImageLoad = () => {
    setLoadedCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (loadedCount === images.length) {
      setLoading(false);
      const timer = setTimeout(() => {
        navigate(UrlMapping.home);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [loadedCount, navigate]);

  return (
    <div className="bg-gradient relative">
      <div className="bg-star-layer absolute opacity-70 w-full h-full animate-pulse"></div>
      <div className="w-full h-[60vh] flex flex-col justify-center items-center">
        <div className="relative w-full max-w-[500px]">
          <div>
            {" "}
            <img
              src={PlanetPng}
              alt="Launch Screen"
              className="absolute w-[70%] top-[50%] left-[50%]"
              style={{
                transform: "translate(-50%, -50%)",
              }}
            />
            <img
              src={ShibaPng}
              alt="Launch Screen"
              className="absolute w-[50%] top-[50%] left-[50%]"
              style={{
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
          <div className="spin-launch-1">
            <img
              src={CirclePng}
              alt="Launch Screen"
              className="absolute top-[50%] left-[50%]"
              style={{
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
          <div className="spin-launch">
            <img
              src={RocketPng}
              alt="Launch Screen"
              className="absolute top-[50%] left-[50%]"
              style={{
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        </div>
      </div>

      <div className="mt-8 h-[35vh] flex flex-col items-center text-white ">
        <img src={LogoPng} alt="Launch Screen" className=" w-[300px]" />
        <h1 className="text-2xl">
          {translations.launch_screen.meme_to_legend}
        </h1>
        <h1
          className="text-3xl text-center animate-bounce mt-8"
          style={{
            background: "linear-gradient(90deg, #E4409C 0%, #FFA2D6 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {translations.launch_screen.launching_king_of_shiba}
        </h1>
      </div>

      {loading && (
        <div className="images-loaded" style={{ display: "none" }}>
          {images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`image-${index}`}
              onLoad={handleImageLoad}
              onError={handleImageLoad}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default LaunchScreen;

import keyValue from "@/commons/key-value";
import api from "@/services/apis/api";
import { IAuthLogin, IResponseAuthLogin } from "./types";

export const authLogin = async (
  body: IAuthLogin
): Promise<IResponseAuthLogin> => {
  const response: IResponseAuthLogin = await api.post(
    "/api/auth/login/mini-app/",
    {
      telegramInitData: body.telegramInitData,
    }
  );
  return response;
};

export const authLogout = () => {
  localStorage.removeItem(keyValue.accessToken);
  localStorage.removeItem(keyValue.user);
};

import api from "@/services/apis/api";
import {
  IVerifySocialTaskRequest,
  IVerifySocialTaskResponse,
  ISocialTaskResponse,
} from "./types";

export const verifySocialTask = async (
  data: IVerifySocialTaskRequest
): Promise<IVerifySocialTaskResponse> => {
  const response: IVerifySocialTaskResponse = await api.post(
    "/api/game/king-of-shiba/social-tasks/verify/",
    data
  );
  return response;
};

export const getTask = async (
  social: string
): Promise<ISocialTaskResponse[]> => {
  const response: ISocialTaskResponse[] = await api.get(
    `/api/game/king-of-shiba/social-tasks/${social}/`
  );
  return response;
};

import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as IconSetting } from "@/assets/icons/users/Setting_fill.svg";
import { ReactComponent as IconUser } from "@/assets/icons/users/User_alt_fill.svg";
import { UrlMapping } from "@/commons/url-mapping.common";

type Props = {
  username: string;
};

const TopUserProfile: React.FC<Props> = ({ username }) => {
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  return (
    <div className="text-center w-full">
      <div className="flex items-center justify-between pt-3">
        <span className="text-sm inline-flex items-center">
          <IconUser className="mr-2 w-5 h-5" />
          <span>{username}</span>
        </span>
        <span
          className="text-sm"
          onClick={() => handleNavigation(UrlMapping.setting)}
        >
          <IconSetting className="mr-2 w-6 h-6" />
        </span>
      </div>
    </div>
  );
};

export default TopUserProfile;

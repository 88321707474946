import {
  SendTransactionRequest,
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import { useEffect, useState } from "react";

export const useTonConnect = () => {
  const [tonConnectUI] = useTonConnectUI();
  const userFriendlyAddress = useTonAddress();
  const rawAddress = useTonAddress(false);
  const wallet = useTonWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  // Check connection status
  useEffect(() => {
    if (wallet) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [wallet]);

  // Send transaction
  const sendTransaction = async (transaction: SendTransactionRequest) => {
    try {
      setIsLoading(true);
      return await tonConnectUI.sendTransaction(transaction);
    } catch (error) {
      console.error("Failed to send transaction:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Connect wallet manually
  const connectWallet = async () => {
    try {
      setIsLoading(true);
      await tonConnectUI.connectWallet();
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Disconnect wallet
  const disconnectWallet = async () => {
    try {
      setIsLoading(true);
      await tonConnectUI.disconnect();
    } catch (error) {
      console.error("Failed to disconnect wallet:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    wallet,
    userFriendlyAddress,
    rawAddress,
    isConnected,
    isLoading,
    connectWallet,
    disconnectWallet,
    sendTransaction,
  };
};

import { createMutation, createQuery } from "react-query-kit";

import {
  checkTelegramVerification,
  getTelegramAuthLink,
  verifyTelegramToken,
} from "./request";
import {
  ITelegramAuthLinkResponse,
  ITelegramVerificationResponse,
  ITelegramVerifyRequest,
} from "./types";

export const useTelegramAuthLink = createQuery({
  queryKey: ["useTelegramAuthLink"],
  fetcher: (): Promise<ITelegramAuthLinkResponse> => getTelegramAuthLink(),
});

export const useVerifyTelegramToken = createMutation<
  ITelegramVerificationResponse,
  ITelegramVerifyRequest
>({
  mutationFn: verifyTelegramToken,
});

export const useCheckTelegramVerification = createQuery({
  queryKey: ["useCheckTelegramVerification"],
  fetcher: (): Promise<ITelegramVerificationResponse> =>
    checkTelegramVerification(),
});

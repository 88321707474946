import { createMutation, createQuery } from "react-query-kit";
import {
  checkInstagramVerification,
  getInstagramAuthLink,
  verifyInstagramToken,
} from "./request";
import {
  IInstagramAuthLinkResponse,
  IInstagramVerificationResponse,
  IInstagramVerifyRequest,
} from "./types";

export const useInstagramAuthLink = createQuery({
  queryKey: ["useInstagramAuthLink"],
  fetcher: (): Promise<IInstagramAuthLinkResponse> => getInstagramAuthLink(),
});

export const useVerifyInstagramToken = createMutation<
  IInstagramVerificationResponse,
  IInstagramVerifyRequest
>({
  mutationFn: verifyInstagramToken,
});

export const useCheckInstagramVerification = createQuery({
  queryKey: ["useCheckInstagramVerification"],
  fetcher: (): Promise<IInstagramVerificationResponse> =>
    checkInstagramVerification(),
});

import GameCoinLogoPng from "@/assets/images/coin-icon.png";
import ProfileImagePng from "@/assets/images/rank-image/profile.png";
import { IFriend } from "@/services/apis/king-of-shiba/types";
import { useConfigStore } from "@/services/stores/useConfigStore";

export const ListFriend: React.FC<{ friends: IFriend[] }> = ({ friends }) => {
  const { translations } = useConfigStore(); // Access translations

  return (
    <div>
      {friends.length === 0 ? (
        <p className="bg-gradient-card text-sm text-gray-400 h-20 flex justify-center items-center rounded-[16px]">
          {translations.list_friend_screen.no_invites}
        </p>
      ) : (
        <ul className="text-sm">
          {friends.map((friend, index) => (
            <li
              key={friend.user_id}
              className="bg-gradient-card flex py-3 justify-around items-center mt-1 rounded-[16px]"
            >
              <div className="text-center text-base mx-2">
                <b>{index + 1}</b>
              </div>
              <div className="text-base flex-1 flex items-center">
                <div className="w-10 h-10 mr-2 relative">
                  <div
                    className="absolute bg-yellow-500 text-white text-xs rounded-full top-[50%] left-[50%] w-10 h-10"
                    style={{
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  <div
                    className="absolute bg-violet-800 text-white text-xs rounded-full top-[50%] left-[50%] w-9 h-9"
                    style={{
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                  <img
                    src={ProfileImagePng}
                    alt="Profile"
                    className="absolute top-[50%] left-[50%] w-8 h-8"
                    style={{
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </div>
                {friend?.username || translations.list_friend_screen.unknown}
              </div>
              <div className="text-yellow-300 text-base flex-1 flex items-center">
                <img
                  src={GameCoinLogoPng}
                  alt="Coin Logo"
                  className="size-5 mx-1"
                />
                <i> {Number(friend.coin_balance).toFixed(2)}</i>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

import GameCoinLogoPng from "@/assets/images/coin-icon.png";
import DogFaceIconPng from "@/assets/images/dog-face.png";
import PerformSocialTaskPng from "@/assets/images/social-task-screen/perform-social-task.png";
import PopupModal from "@/components/PopupModal";
import {
  ISocialTaskResponse,
  useVerifySocialTask,
} from "@/services/apis/social-task";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import the store for translations
import { copyToClipboard } from "@/utils/copy";
import {
  CodeValidateChoices,
  UserSocialActionStatusChoices,
} from "@/utils/enum";
import React, { useEffect, useState } from "react";

type TaskDetailModalProps = {
  task: ISocialTaskResponse | null;
  open: boolean;
  onClose: () => void;
};

const TaskDetailModal: React.FC<TaskDetailModalProps> = ({
  task,
  open,
  onClose,
}) => {
  const { translations } = useConfigStore(); // Access translations
  const { mutate, isPending, isError, isSuccess } = useVerifySocialTask();
  const [code, setCode] = useState(""); // State to track code input
  const [generatedCode, setGeneratedCode] = useState(""); // State for random code
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Optional state for validation errors

  // Generate a random code for VALIDATE_CODE
  const generateRandomCode = () => {
    const randomCode = Math.random()
      .toString(36)
      .substring(2, 25)
      .toUpperCase();
    setGeneratedCode(`#KingOfShiba.xyz ${randomCode}`);
  };

  // Generate the random code when VALIDATE_CODE option is selected
  useEffect(() => {
    if (task?.code_validate_option === CodeValidateChoices.VALIDATE_CODE) {
      generateRandomCode();
    }
  }, [task?.code_validate_option]);

  const handleVerify = () => {
    if (!task) return;

    if (
      task?.code_validate_option === CodeValidateChoices.INPUT_CODE &&
      code.trim() === ""
    ) {
      setErrorMessage(translations.task_modal.enter_valid_code); // Translation for validation error
      return;
    }

    mutate(
      {
        social_task_id: task.id,
        data:
          task?.code_validate_option === CodeValidateChoices.VALIDATE_CODE
            ? generatedCode
            : code, // Use generated code for VALIDATE_CODE
      },
      {
        onSuccess: () => {
          console.log(translations.task_modal.verification_process_message);
        },
        onError: (error) => {
          console.error(
            translations.task_modal.verification_failed_message,
            error
          );
        },
      }
    );
  };

  return (
    <PopupModal open={open} onClose={onClose}>
      <div className="flex flex-col items-center space-y-4 w-[90%]">
        <div className="w-full flex justify-center mb-4">
          <img
            src={PerformSocialTaskPng}
            alt={translations.task_modal.shiba_character}
            className="h-[30vh] "
          />
        </div>

        {/* Main Message */}
        <h2 className="text-3xl font-bold text-white text-center">
          {task?.name}
        </h2>

        {/* Reward Section */}
        <div className="flex items-center space-x-2 text-yellow-400 text-lg font-bold">
          <img src={GameCoinLogoPng} alt="Coin Icon" className="w-6 h-6" />
          <span className="text-2xl">+{task?.reward_amount}</span>
        </div>

        {/* Code Input */}
        {task?.code_validate_option === CodeValidateChoices.INPUT_CODE && (
          <div className="flex flex-col items-center w-full">
            <input
              type="text"
              value={code}
              onChange={(e) => {
                setCode(e.target.value);
                setErrorMessage(null);
              }}
              placeholder={translations.task_modal.enter_verification_code}
              className="p-2 rounded border border-gray-300 focus:outline-none focus:ring focus:border-blue-500 w-full text-black"
            />
            {errorMessage && (
              <p className="text-red-500 mt-1 text-sm">{errorMessage}</p>
            )}
          </div>
        )}

        {/* Display Random Code for VALIDATE_CODE */}
        {task?.code_validate_option === CodeValidateChoices.VALIDATE_CODE && (
          <div className="w-full flex flex-col items-center bg-white px-2 py-2 rounded-[16px] text-sm font-semibold hover:bg-slate-200 border-4 border-purple-500 shadow-lg transition">
            <p
              className="text-[#F58231] font-bold cursor-pointer text-center text-lg"
              onClick={() => copyToClipboard(generatedCode)}
            >
              {translations.task_modal.comment_hashtag}:{" "}
              {task?.data ? task?.data : generatedCode}
            </p>
          </div>
        )}

        {/* Social Info Section */}
        <div className="flex justify-between w-full">
          <div className="flex items-center p-2 h-[3rem] leading-[1.7rem] bg-gradient-card text-white font-bold hover:bg-blue-700 rounded-[16px] w-[85%] text-center text-base mr-2">
            <img
              src={DogFaceIconPng}
              alt="Avatar"
              className="w-8 h-8 rounded-full"
            />
            <span className="text-white font-bold">
              {task?.action_type_name}
            </span>
          </div>

          <a
            href={task?.target_url}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 h-[3rem] w-[3rem] bg-gradient-button text-white font-bold hover:bg-blue-700 rounded-[16px] flex justify-center items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="20"
              height="20"
              viewBox="0 0 48 48"
            >
              <path
                fill="white"
                d="M 40.960938 4.9804688 A 2.0002 2.0002 0 0 0 40.740234 5 L 28 5 A 2.0002 2.0002 0 1 0 28 9 L 36.171875 9 L 22.585938 22.585938 A 2.0002 2.0002 0 1 0 25.414062 25.414062 L 39 11.828125 L 39 20 A 2.0002 2.0002 0 1 0 43 20 L 43 7.2460938 A 2.0002 2.0002 0 0 0 40.960938 4.9804688 z M 12.5 8 C 8.3826878 8 5 11.382688 5 15.5 L 5 35.5 C 5 39.617312 8.3826878 43 12.5 43 L 32.5 43 C 36.617312 43 40 39.617312 40 35.5 L 40 26 A 2.0002 2.0002 0 1 0 36 26 L 36 35.5 C 36 37.446688 34.446688 39 32.5 39 L 12.5 39 C 10.553312 39 9 37.446688 9 35.5 L 9 15.5 C 9 13.553312 10.553312 12 12.5 12 L 22 12 A 2.0002 2.0002 0 1 0 22 8 L 12.5 8 z"
              ></path>
            </svg>
          </a>
        </div>

        {/* Confirm Button */}
        <button
          onClick={handleVerify}
          className={`py-3 rounded-[16px] mt-4 w-full font-bold text-white transition-all duration-300 ease-in-out bg-gradient-button hover:shadow-lg ${
            isPending ||
            task?.status_action ===
              UserSocialActionStatusChoices.REQUEST_VERIFY ||
            task?.status_action === UserSocialActionStatusChoices.VERIFIED
              ? "opacity-75"
              : null
          }`}
          disabled={
            isPending ||
            (task?.code_validate_option === CodeValidateChoices.INPUT_CODE &&
              code.trim() === "") || // Disable button if code is empty
            task?.status_action ===
              UserSocialActionStatusChoices.REQUEST_VERIFY ||
            task?.status_action === UserSocialActionStatusChoices.VERIFIED
          }
        >
          {isPending
            ? translations.task_modal.verifying
            : translations.task_modal.verify_now}
        </button>

        {/* Success or Error Feedback */}
        {task?.status_action === UserSocialActionStatusChoices.VERIFIED && (
          <p className="text-yellow-300 text-center">
            {translations.task_modal.verified_message}
          </p>
        )}

        {(isSuccess ||
          task?.status_action ===
            UserSocialActionStatusChoices.REQUEST_VERIFY) && (
          <p className="text-yellow-300 text-center">
            {translations.task_modal.verification_started_message}
          </p>
        )}
        {isError && (
          <p className="text-red-500 text-center">
            {translations.task_modal.verification_failed_message}
          </p>
        )}
      </div>
    </PopupModal>
  );
};

export default TaskDetailModal;

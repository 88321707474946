import api from "@/services/apis/api";
import {
  IFacebookAuthLinkResponse,
  IFacebookVerifyRequest,
  IFacebookVerificationResponse,
} from "./types";

export const getFacebookAuthLink =
  async (): Promise<IFacebookAuthLinkResponse> => {
    const response: IFacebookAuthLinkResponse = await api.get(
      "/api/user/social/facebook/auth-link/"
    );
    return response;
  };

export const verifyFacebookToken = async (
  data: IFacebookVerifyRequest
): Promise<IFacebookVerificationResponse> => {
  const response: IFacebookVerificationResponse = await api.post(
    "/api/user/social/facebook/verify/",
    data
  );
  return response;
};

export const checkFacebookVerification =
  async (): Promise<IFacebookVerificationResponse> => {
    const response: IFacebookVerificationResponse = await api.get(
      "/api/user/social/facebook/is-verified/"
    );
    return response;
  };

import React, { useEffect, useRef, useState } from "react";
import DailyTaskDetailPng from "@/assets/images/earn-screen/daily-task-screen/daily-task-detail.png";
import IconTickPng from "@/assets/images/earn-screen/daily-task-screen/icon-tick.png";
import IconUnTickPng from "@/assets/images/earn-screen/daily-task-screen/icon-untick.png";
import showToast from "@/commons/toast.common";
import PhoneScreenLayout from "@/components/layouts/PhoneScreenLayout";
import NavBar from "@/components/menus/NavBar";
import TopUserProfile from "@/components/top-user-profile/TopUserProfile";
import {
  IResponseCheckIn,
  useCheckIn,
  useCheckInInfo,
} from "@/services/apis/king-of-shiba";
import { useAuthStore } from "@/services/stores/useAuthStore";
import { useConfigStore } from "@/services/stores/useConfigStore"; // Import translations

const DailyTaskDetailScreen: React.FC = () => {
  const { getUserName } = useAuthStore();
  const { translations } = useConfigStore(); // Access translations
  const { data: dataCheckInInfo, refetch } = useCheckInInfo({});
  const { mutate } = useCheckIn({
    onSuccess: (data: IResponseCheckIn) => {
      showToast(data.message, "success");
      refetch();
    },
  });

  const onClickConfirm = () => {
    mutate();
  };

  const [imageHeight, setImageHeight] = useState(0);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (imageRef.current) {
      setImageHeight(imageRef.current.height);
    }
  }, []);

  return (
    <PhoneScreenLayout>
      <TopUserProfile username={getUserName()} />
      <div className="">
        <section
          className="w-full relative"
          style={{ height: imageHeight ? `${imageHeight}px` : "auto" }}
        >
          <img
            src={DailyTaskDetailPng}
            alt={translations.daily_task_screen.daily_task_image_alt}
            className="absolute left-[-20px]"
            ref={imageRef}
            onLoad={() => {
              if (imageRef.current) {
                setImageHeight(imageRef.current.height);
              }
            }}
          />
        </section>
        <section className="w-full text-center pb-4">
          <h2 className="text-xl font-semibold mb-1">
            {translations.daily_task_screen.title}
          </h2>
          <h4 className="font-semibold">
            {translations.daily_task_screen.check_in_prompt}
          </h4>
        </section>

        <section>
          <div className="grid grid-cols-4 gap-6">
            {dataCheckInInfo?.token_rewards.map(
              (reward: number, index: number) => {
                const dateNumber = index + 1;
                return (
                  <div
                    className="min-w-[4rem] min-h-[7rem] bg-gradient-card rounded-[16px] flex flex-col justify-evenly items-center text-center"
                    key={dateNumber}
                  >
                    <div>
                      {translations.daily_task_screen.day} {dateNumber}
                    </div>
                    <div className="text-[#F0F900]">
                      <i>+{reward}💰</i>
                    </div>
                    {dateNumber <= dataCheckInInfo.current_day ? (
                      <img
                        src={IconTickPng}
                        alt={translations.daily_task_screen.completed}
                        className="w-6 h-6"
                      />
                    ) : (
                      <img
                        src={IconUnTickPng}
                        alt={translations.daily_task_screen.not_completed}
                        className="w-6 h-6"
                      />
                    )}
                  </div>
                );
              }
            )}
          </div>
        </section>
        <button
          className={`w-full p-3 bg-gradient-button rounded-[16px] mt-5 text-lg ${
            !dataCheckInInfo?.able_claim && "opacity-70 cursor-not-allowed"
          }`}
          disabled={!dataCheckInInfo?.able_claim}
          onClick={onClickConfirm}
        >
          {dataCheckInInfo?.able_claim
            ? translations.daily_task_screen.confirm
            : translations.daily_task_screen.confirmed}
        </button>
      </div>
      <NavBar />
    </PhoneScreenLayout>
  );
};

export default DailyTaskDetailScreen;
